/* You can add global styles to this file, and also import other style files */

:root{
    --color-primary: #343A40;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

h6{
    font-size: 1rem !important;
}

.container{
    width: 100%;
    padding: 1rem;
    padding-bottom: 3.8rem;
}

.card-custom {
    width: 90%;
}

.contenedor {
    min-height: 70%;
}

.contenedor-acciones {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.card{
    border-radius: 1rem;
    border: none;
    //box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    box-shadow: 23.517px 33.585px 46px 0px rgb(192 197 202 / 26%);
    // padding: 2rem 3rem 2rem 3rem;
}

.title{
    font-size: 1.5rem;
    color: var(--color-primary);
}

.btn-primario {
    background-color: #343A40;
    color: white;
    //min-width: 10rem;
    border-radius: 1rem;
    font-size: 1rem;
    font-weight: 500;
}

.btn-secundario {
    background-color: white;
    color: #343A40;
    border-color: #343A40;
    border-radius: 1rem;
    //min-width: 10rem;
    font-size: 1rem;
    font-weight: 500;
}

.btn-secundario:hover {
    background-color: #343A40;
    color: white;
}

.btn-form{
    display: flex !important;
    justify-content: space-between;
}

.breadcrumb {
    font-size: 0.8rem;
    span{
        cursor: pointer;
        font-weight: 500;
        margin-right: 0.2rem;
    }
    span:hover{
        color: #343A4080;
    }
}

form {
    padding: 0 3rem 0 3rem;
}


.success {
    // background: #00B92F;
    // color: #343A40;
    background: #198754;
    color: white;
}

.info {
    background: #0d6efd;
    color: #343A40;
}

.warning {
    background: #ffc107;
    color: #343A40;
}

.error {
    background: #E3242B;
    color: white;
}
